.start-playing-listings {
    width : 100%;
    margin: auto;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    padding: 0px 200px 40px 200px;
  }
  .card-start-playing {
    border-radius: 8px !important;
    border: 1px solid #03fc1c !important;
    background: rgba(31, 31, 31, 0.5) !important;
    padding: 22px 23px !important;
    width: 570px !important;
    height: 350px !important;
    flex-direction: column !important;
    align-items: center !important;
    display: flex !important;
    gap: 25px !important;
  }
  .card-gamming {
    border-radius: 8px !important;
    border: 1px solid #03fc1c !important;
    background: rgba(31, 31, 31, 0.5) !important;
    padding: 22px 23px !important;
    width: 570px !important;
    height: 350px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    display: flex !important;
    gap: 25px !important;
  }

  .start-playing-text {
    color: #03fc1c;
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 24px;
    text-align: center;
  }

  /* Mobile & Tablet */


  @media only screen and (max-width: 600px) {
    .start-playing-listings{
        font-size: 20px;
        width: 100%;
        padding: 0;
    }
      .start-playing-text {
    font-size: 24px;
  }
    .card-start-playing {
      margin-top: 10px !important;
      width: 340px !important;
      height: auto !important;
      padding: 24px !important;
    }
    
    .description-bg {
      padding: 24px 5px;
    }
    .what-exactly-is {
      padding-top: 0px;
      font-size: 32px;
    }
    .fire-kirin-is {
      padding-top: 24px;
      padding-left: 0px;
      padding-right: 0px;
      font-size: 18px;
    }
    .variety-wrapper {
      padding-top: 24px;
      padding-bottom: 34px;
    }
    .variety-of-games {
      font-size: 24px;
      padding-top: 16px;
    }
    .from-the-fast-paced {
      font-size: 16px;
    }
    .official-facebook-pages {
      font-size: 32px;
    }
    .please-contact-our-container {
      font-size: 18px;
      line-height: 25px;
    }
    .card-gamming-wrapper {
      display: flex;
      justify-content: center;
    }
    .vip-gaming {
      font-size: 20px;
    }
    .vip-gaming-is {
      font-size: 14px;
      line-height: 24px;
      text-align: start;
    }
    .facebook-fill {
      position: static;
    }
  }

  /* Tablet */
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .start-playing-text {
        text-align: center;
        font-size: 24px;
      }
    .what-exactly-is {
      padding-top: 103px;
      font-size: 48px;
    }
    .fire-kirin-is {
      padding-left: 0px;
      padding-right: 0px;
    }
    .variety-wrapper {
      padding-top: 0px;
      padding-bottom: 100px;
    }
    .variety-of-games {
      padding-top: 40px;
    }
    .from-the-fast-paced {
      padding-left: 140px;
      padding-right: 140px;
    }
    .official-facebook-pages {
      font-size: 32px;
    }
    .please-contact-our-container {
      font-size: 20px;
    }
    .card-start-playing {
      width: auto !important;
      height: auto !important;
      padding: 24px !important;
      gap: 10px !important;
    }
    .card-gamming {
        width: auto !important;
        height: 270px !important;
        padding: 24px !important;
        gap: 10px !important;
      }
    .facebook-fill {
      box-sizing: border-box;
      display: flex;
      position: absolute;
      top: 200px;
    }
  }