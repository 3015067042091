/* Container style */
.scroll-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: auto;
    align-items: center;
    justify-content: center;
    padding: 3px;
  }
  
  /* Button style */
  .scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #024d21;
    color: #ffffff;
    border-radius: 30px;
    cursor: pointer;
    width: 55px;
    height: 55px;
    border: none;
  }

  .scroll-button:hover {
    /* background-color: #024d21; */
    border: none;
    color : white;
    /* Add a drop shadow */
    /* box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.445); Horizontal, vertical, blur radius, spread radius, color */
  }
  