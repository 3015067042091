.pagination-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding-bottom: 50px;
}

.ul-override {
  margin-bottom: 0rem;
  padding-left: 0rem;
}

.pagination-container .pagination-item {
  padding: 25px 22px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: #fff;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 50%;
  line-height: 1.43;
  font-size: 18px;
  min-width: 32px;

  /* display: flex;
  width: 40px;
  height: 40px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 50px;
  border: 2px solid #024d21;
  background: #000;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; */
}

.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-container .pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.pagination-container .pagination-item.selected {
  background-color: #024d21;
}

.pagination-container .pagination-item .arrow::before {
  position: relative;
  content: "";
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.pagination-container .pagination-item .arrow.left {
  padding: 12px 24px;
  border-radius: 2px;
  border: 1px solid #03fc1c;
  background: #000;
  color: #03fc1c;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.pagination-container .pagination-item .arrow.right {
  padding: 12px 24px;
  border-radius: 2px;
  border: 1px solid #03fc1c;
  background: #000;
  color: #03fc1c;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.pagination-container .pagination-item.disabled {
  pointer-events: none;
}

.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pagination-container {
    padding-left: 0px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
  }
  .pagination-container .pagination-item {
    padding-top: 20px;
    padding-right: 18px;
    padding-bottom: 20px;
    padding-left: 18px;
    font-size: 15px;
  }
  .pagination-container .pagination-item .arrow.left {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 13px;
    padding-right: 13px;
    font-size: 15px;
  }
  .pagination-container .pagination-item .arrow.right {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 13px;
    padding-right: 13px;
    font-size: 15px;
  }
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .pagination-container {
    padding-left: 0px;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
  }
  .pagination-container .pagination-item {
    padding: 0px;
  }
  .pagination-container .pagination-item .arrow.left {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 50px;
  }
  .pagination-container .pagination-item .arrow.right {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 50px;
  }
  .pagination-wrapper {
    display: flex;
    justify-content: center;
    list-style-type: none;
  }
  .tuga {
    display: none !important;
  }
}
