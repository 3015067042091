.banner-bg {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    url("../../public/assets/banner-laptop-bg.svg") lightgray 50% / cover
      no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  width: 100%;
  height: 1180px;
}
.banner-wrapper {
  padding-top: 280px;
  padding-bottom: 539px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.the-ultimate-fish {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.fire-kirin-online {
  color: #03fc1c;
  text-align: center;
  font-family: "Metal Mania";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.banner-button {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 12px 24px;
  border-radius: 4px;
  border: 1px solid #03fc1c;
  background: #024d21;
  box-shadow: 4px 4px 50px 0px rgba(3, 252, 28, 0.25);
}
.get-free-fire {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.banner-container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  max-width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  width: 950px;
  padding: 0 30px;
  box-sizing: border-box;
  gap: 24px;
}
.now-play {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.signup-txt {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.official-facebook-pages {
  color: #03fc1c;
  text-align: center;
  font-family: "Metal Mania";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.please-contact-our-container {
  margin-top: 16px;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.card-gamming-social {
  border-radius: 8px !important;
  border: 1px solid #03fc1c !important;
  background: white !important;
  padding: 22px 23px !important;
  width: 472px !important;
  height: 214px !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  display: flex !important;
  gap: 25px !important;
}
.vip-gaming {
  color: #03fc1c;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.vip-gaming-is-social {
  color: black;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
}
.facebook-fill {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  position: absolute;
  top: 158px;
}
/* nov 6 H1 css */
.container_h1 {
  padding: 0px 200px;
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .banner-bg {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      url("../../public/assets/banner-tablet-bg.svg") lightgray 50% / cover
        no-repeat;
    height: 998px;
  }
  .container_h1 {
    padding: 0px;
  }

  .banner-wrapper {
    padding-top: 472px;
    padding-bottom: 339px;
  }
  .fire-kirin-online {
    font-size: 48px;
  }
  .banner-container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    max-width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    width: 635px;
    padding: 0 30px;
    box-sizing: border-box;
    gap: 24px;
  }
  .now-play {
    font-size: 24px;
  }
  .signup-txt {
    font-size: 24px;
  }
  .official-facebook-pages {
    font-size: 32px;
  }
  .please-contact-our-container {
    font-size: 20px;
  }
  .card-gamming-social {
    width: auto !important;
    height: 270px !important;
    padding: 24px !important;
    gap: 10px !important;
  }
  .facebook-fill {
    box-sizing: border-box;
    display: flex;
    position: absolute;
    top: 200px;
  }
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .banner-bg {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      url("../../public/assets/banner-mobile-bg.svg") lightgray 50% / cover
        no-repeat;
    height: 600px;
  }
  .container_h1 {
    padding: 20px;
  }
  .banner-wrapper {
    padding-top: 261px;
    padding-bottom: 202px;
  }
  .the-ultimate-fish {
    font-size: 18px;
  }
  .fire-kirin-online {
    font-size: 32px;
  }
  .now-play {
    font-size: 18px;
  }
  .signup-txt {
    font-size: 18px;
  }
  .official-facebook-pages {
    font-size: 32px;
  }
  .please-contact-our-container {
    font-size: 18px;
    line-height: 25px;
  }
  .card-gamming-wrapper {
    display: flex;
    justify-content: center;
  }
  .card-gamming-social {
    margin-top: 10px !important;
    width: 340px !important;
    height: auto !important;
    padding: 24px !important;
  }
  .vip-gaming {
    font-size: 20px;
  }
  .vip-gaming-is {
    font-size: 14px;
    line-height: 24px;
    text-align: start;
  }
  .facebook-fill {
    position: static;
  }
}
