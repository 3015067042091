.announcement-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  background: #024d21;
  color: #ffffff;
  padding: 10px 0;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 4px 2px -2px gray;
  position: relative;
  height: 45px; /* Adjust according to your design */
  position: sticky;
  top: 0;
  z-index: 112;
}

.announcement-text {
  display: inline-block;
  padding-left: 50%;
  animation: marquee 20s linear infinite;
  font-size: 20px;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
