.nav-logo img {
  width: 75px;
  height: auto;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  padding-top: 62px;
  padding-left: 100px;
  padding-right: 100px;
  background-color: black;
}

.navbar {
  --bs-navbar-padding-y: 0rem !important;
}

.nav-font {
  color: #fff !important;
  font-family: Roboto !important;
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.nav-button {
  padding: 12px 24px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #03fc1c;
  background: #024d21;
  box-shadow: 4px 4px 50px 0px rgba(3, 252, 28, 0.25);
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("../../public/assets/menu-line.svg");
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-lg,
  .navbar > .container-md,
  .navbar > .container-sm,
  .navbar > .container-xl,
  .navbar > .container-xxl {
    padding-left: 40px;
    padding-right: 40px;
  }

  .nav-logo img {
    width: 60px;
    height: auto;
  }
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .nav-logo {
    width: 62.4px;
    height: 48px;
  }

  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-lg,
  .navbar > .container-md,
  .navbar > .container-sm,
  .navbar > .container-xl,
  .navbar > .container-xxl {
    padding-top: 70px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .nav-logo img {
    width: 55px;
    height: auto;
  }
}
