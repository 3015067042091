a.active{
    text-decoration: underline;
}

section.blog-category {
    padding: 120px 0 0 0;
    background-color: #000;
    color: #fff;
}
.blog-category h1 {
    text-align: center;
    font-size: 50px;
    margin-bottom: 30px;
}
.common-blog {
    border: 1px solid #03fc1c;
    box-shadow: 0 0 17px 0 #03fc1c;
    color: #fff;
}
.common-blog img {
    width: 100%;
}
.common-blog-content {
    padding: 10px 20px 20px;
    text-align: center;
}
.common-blog-content h4 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 1.4;
    margin-bottom: 30px;
    overflow: hidden;
}
.common-blog-content button {
    background: #024d21;
    border: none;
    border-radius: 35px;
    box-shadow: 0 0 17px 0 #03fc1c;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 24px;
    transition: .3s;
}
.common-recent-detail {
    max-height: 100vh;
    overflow-x: auto;
    padding-bottom: 20px;
    position: sticky;
    top: 150px;
}
.blog-head.blog-details img {
    max-height: 500px;
}
.recent-post, .recent-post h3 {
    color: #fff;
    margin-bottom: 20px;
}
.recent-post {
    border: 1px solid #03fc1c;
    padding: 20px;
}
.recent-post h3 {
    font-size: 25px;
}
.common-post a {
    align-items: center;
    color: #fff;
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
    text-decoration: none;
img {
    max-width: 100px;
    width: 100%;
}
p {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin-bottom: 0;
    overflow: hidden;
}

}

section.all-blog {
    background-blend-mode: overlay;
    background-color: #000;
    /* background-image: url(/static/media/blog-bg.050f4a8a8b1127476b09.svg); */
    background-size: cover;
    color: #fff;
    padding: 100px 0 0;
}
.blog-head {
    color: #fff;
    margin-bottom: 40px;
    text-align: center;
}
.blog-details h1 {
    margin-bottom: 40px;
}
.details-content {
    padding: 20px 0;
}
.details-content p {
    font-size: 16px;
    line-height: 1.7;
}
.details-one {
    scroll-margin-top: 130px;
    margin-bottom: 2rem;
}
.details-one h4 {
    font-size: 22px;
}
.details-one img {
    margin: 20px 0;
    width: 100%;
}
.recent-post ul {
    line-height: 1.7;
    padding-left: 20px;
}
.recent-post ul li a {
    color: #fff;
    text-decoration: none;
}
.share-icon ul {
    display: flex;
    gap: 20px;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.share-icon ul li {
    border: 1px solid #fff;
    border-radius: 5px;
    height: 30px;
    text-align: center;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.share-icon ul li svg {
    width: 20px;
    height: 20px;
}
.details-content p a {
    color: #03fc1c;
}

.card-override::before {
    width: 100%;
    height: 100%;
    background-color: #00000063;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    content: "";
    border-radius: 10px;
    opacity: 0;
    transition: .2s;
}
.card-override:hover::before {
    opacity: 1;
}

.card_btn {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    transform: translate(0px, -50%);
    transition: .5s;
    opacity: 0;
    z-index: 9;
}
.card-override:hover .card_btn {
    opacity: 1;
}
.card_btn button.btn.btn-secondary {
    padding: 10px 24px;
    border-radius: 4px;
    border: 1px solid #03fc1c;
    background: #024d21;
    box-shadow: 4px 4px 50px 0px rgba(3, 252, 28, 0.25);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    width: 70%;
    transition: .5s;
}

.card_btn button.btn.btn-secondary:hover{
    transform: scale(1.1);
}

/* Terms & condition */

.common-terms-content p, .details-one ul li {
    font-size: 18px;
    line-height: 1.7;
}
.common-terms-one{
    margin-bottom: 2rem;
}

@media screen and (min-width: 992px){
.col-lg-4.mobile-show {
    display: none;
}
}

@media screen and (max-width:991px){
    .recent-post.mobile-none {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .blog-head h1 {
        font-size: 40px;
    }
}
@media screen and (max-width: 767px) {
    .blog-head h1 {
        font-size: 30px;
    }
    .recent-post h3 {
        font-size: 22px;
     }
     .footer-bg {
        padding-top: 60px;
    }
    .common-terms-content p, .details-one ul li {
        font-size: 16px;
        line-height: 1.6;
    }
    
}

@media only screen and (max-width: 600px) {

    html,body
    {
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        overflow-x: hidden; 
    }
  
  }