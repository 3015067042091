.footer-bg {
  background: #000;
  padding-top: 88px;
}

.footer-txt-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
}

.footer-txt {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 900px;
  width: 100%;
}

.fire-kirin-portal {
  color: #fff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.footer-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 56px;
  padding-bottom: 56px;
}

.copyright {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 25.5px;
  padding-bottom: 25.5px;
}
.footer-links {
  margin: auto;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 25.5px;
  padding-bottom: 25.5px;
}
.footer-links ul {
  display: flex;
  justify-content: center;
  

}

.footer-links li {
  list-style-type: none;
  margin: 0 20px;
  cursor: pointer;
}
.footer-links li a {
  color: #fff !important;
  font-family: Roboto !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.footer-links li a:hover{
  color: #037733 !important;
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .footer-bg {
    padding-top: 60px;
  }
  .footer-txt {
    max-width: 664px;
    width: 100%;
  }
}


/* Mobile */
@media only screen and (max-width: 600px) {
  .footer-bg {
    padding-top: 50px;
  }
  .footer-txt {
    max-width: 358px;
    width: 100%;
    font-size: 20px;
  }
  .fire-kirin-portal {
    font-size: 20px;
  }
  .copyright {
    font-size: 20px;
  }
  .footer-links {
    font-size: 20px;
  }
}
